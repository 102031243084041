import React from "react"
import SectionBase from "./base";
import { Title } from "../atoms/titles";
import { Html } from "../atoms/text";
//import { Link } from "gatsby"

const SectionContent = ({
  title = '',
  description = '',
  className = "",
  classNameTitle = "text-[60px] font-bold",
  classNameBody = "text-[22px]",
  classNameContainer = "container",
  style = {},
  children
}) => {
  return (
    <SectionBase className={className} style={style}>
      <div className={classNameContainer}>
        <Title className={classNameTitle}>{title}</Title>
        <Html className={classNameBody}>{description}</Html>
        {children}
      </div>
    </SectionBase>

  )
}

const SectionContentExample = () => {
  return (
    <SectionContent
      className="pt-32 pb-12 lg:pt-48 lg:pb-32 text-white bg-dark"
      title="רשום אותי"
      description="בל הצעות מיוחדות, חדשות מוצר בלעדיות ומידע על אירועים ישירות לתיבת הדואר הנכנס שלך"
    />
  )
}

export default SectionContent;
export { SectionContentExample };
