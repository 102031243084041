import React from "react";
import Layout from "../components/layouts";
import SectionContent from "../components/sections/content";
import { graphql } from "gatsby";

const BasicPage = ({ data: { node } }) => {
  return (
    <Layout>
      <SectionContent
        title={node.title}
        description={node.body.processed}
      ></SectionContent>
    </Layout>
  );
};

export default BasicPage;

export const query = graphql`
  query basic($id: String!) {
    node: nodePage(id: { eq: $id }) {
      id
      title
      metatag {
        attributes {
          content
          name
        }
        tag
      }
      path {
        alias
      }
      body {
        processed
      }
    }
  }
`;
